<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-row class="mt-2">
    <v-col class="pl-0">
      <v-chip
        id="iiotGpgPublicKeyDownloadBtn"
        color="white"
        data-cy="iiotGpgPublicKeyDownloadBtn"
        @click="downloadGPGPublicKeyFile()"
      >
        <span id="iiotGpgPublicKeyDownloadBtnText" data-cy="iiotGpgPublicKeyDownloadBtnText">
          {{ $t('usageReports.list.downloadPublicKeyLabel') }}
        </span>
        <v-icon id="iiotGpgPublicKeyDownloadBtnIcon" right size="24" data-cy="iiotGpgPublicKeyDownloadBtnIcon">
          mdi-file-download-outline
        </v-icon>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    config() {
      return this.$store.getters['usage-reports/config'];
    },
  },
  methods: {
    downloadGPGPublicKeyFile() {
      const url = window.URL.createObjectURL(new Blob([this.config.gpgPublicKey]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'pub.asc');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
#iiotGpgPublicKeyDownloadBtnText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#iiotGpgPublicKeyDownloadBtnText:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: normal;
}
</style>
