<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div>
    <v-container fluid>
      <v-row justify="end">
        <h4 id="iiotAvailableUpdatesListCurrentVersion" data-cy="iiotAvailableUpdatesListCurrentVersion">
          {{
            $t('availableUpdates.list.currentVersion', {
              gitCommit: version.gitCommit,
              buildDate: version.buildDate,
            })
          }}
        </h4>
      </v-row>
    </v-container>
    <div>
      <v-row>
        <v-col cols="12" lg="6">
          <the-nerve-table
            id="iiotAvailableUpdatesList"
            :columns="availableUpdatesColumn"
            :is-row-clickable="canAccess('VERSION:UPDATE')"
            :is-action-menu-enabled="false"
            :have-access="canAccess('UI_VERSION:UPDATE')"
            store-module="version"
            custom-fetch-action="get_available_versions"
            custom-list-getter="getAvailableVersions"
            :is-search-enabled="false"
            :is-filter-columns-enabled="false"
            :disable-pagination="true"
            :hide-footer="true"
            :empty-state="$t('availableUpdates.list.emptyState')"
            data-cy="iiotAvailableUpdatesList"
            @row-clicked="(params) => showUpdateVersion(params.item)"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <div
            v-if="isUpdateVersion"
            id="iiotAvailableUpdatesListUpdateVersion"
            data-cy="iiotAvailableUpdatesListUpdateVersion"
          >
            <v-card
              id="iiotAvailableUpdatesListUpdateCard"
              data-cy="iiotAvailableUpdatesListUpdateCard"
              class="mb-1 mr-1"
            >
              <v-toolbar dark>
                <v-toolbar-title
                  id="iiotAvailableUpdatesListToolbarTitle"
                  data-cy="iiotAvailableUpdatesListToolbarTitle"
                >
                  {{
                    $t('availableUpdates.list.updateVersion', {
                      version: updateVersion.version,
                    })
                  }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-subtitle
                id="iiotAvailableUpdatesListUpdateMessage"
                class="pl-6 pr-6 black--text"
                data-cy="iiotAvailableUpdatesListUpdateMessage"
              >
                {{ $t('availableUpdates.list.message') }}
              </v-card-subtitle>
              <v-card-text class="pl-6 pr-6">
                <v-alert
                  v-if="getIncompatibleNodes.length !== 0"
                  id="iiotAvailableUpdatesListOfNotCompatibleNodesAlert"
                  text
                  type="error"
                  color="#FF0500"
                  prominent
                  data-cy="iiotAvailableUpdatesListOfNotCompatibleNodesAlert"
                >
                  {{ $t('availableUpdates.list.listOfNotCompatibleNodes') }}
                  <v-list
                    id="iiotAvailableUpdatesListOfNotCompatibleNodes"
                    dense
                    data-cy="iiotAvailableUpdatesListOfNotCompatibleNodes"
                    max-height="20vh"
                    class="overflow-y-auto"
                    light
                  >
                    <v-list-item v-for="(nonCompatibleNode, index) in getIncompatibleNodes" :key="index">
                      <v-list-item-icon class="mr-1 mt-1">
                        <v-icon x-small color="#FF0500">fiber_manual_record</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t(nonCompatibleNode.name) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-alert>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';

export default {
  components: { TheNerveTable },
  data() {
    return {
      isUpdateVersion: false,
      updateVersion: '',
      params: {},
    };
  },
  computed: {
    availableUpdatesColumn() {
      return [
        {
          text: this.$t('availableUpdates.list.versionNumber'),
          value: 'version',
          sortable: false,
        },
        {
          text: this.$t('availableUpdates.list.releaseDate'),
          value: 'release_date',
          sortable: false,
        },
      ];
    },
    version() {
      return this.$store.getters['version/getCurrentVersion']();
    },
    getIncompatibleNodes() {
      return this.$store.getters['version/getIncompatibleNodes']();
    },
  },
  methods: {
    async showUpdateVersion(update) {
      try {
        this.updateVersion = update;
        await this.$store.dispatch('version/get_incompatible_nodes', this.updateVersion.version);
        this.isUpdateVersion = true;
        this.params.version = this.updateVersion.version;
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>
<style scoped>
#iiotAvailableUpdatesListUpdateVersion {
  margin-top: 72px;
}
.v-list-item {
  color: #ff0500 !important;
  min-height: 30px;
  height: 30px;
}
#iiotAvailableUpdatesListToolbarTitle {
  font-size: 1.25rem !important;
}
</style>
