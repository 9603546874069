<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div v-if="config.tttechEmail" class="d-inline-flex mt-12">
    <div id="iiotNotifyTTTechSwitchText" class="mt-5 mr-2" data-cy="iiotNotifyTTTechSwitchText">
      {{ $t('usageReports.list.tttechNotifyText') }}
      <a :href="`mailto:${config.tttechEmail}`">{{ config.tttechEmail }}</a>
    </div>
    <v-switch
      id="iiotNotifyTTTechSwitch"
      v-model="config.notifyTTTech"
      data-cy="iiotNotifyTTTechSwitch"
      class="width-secure-connection-field"
      @change="switchTTTechNotification"
    />
  </div>
</template>

<script>
export default {
  computed: {
    config() {
      return this.$store.getters['usage-reports/config'];
    },
  },
  mounted() {
    try {
      this.$store.dispatch('usage-reports/get_config');
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    switchTTTechNotification(value) {
      try {
        this.$store.dispatch('usage-reports/update_config', { notifyTTTech: value });
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>
