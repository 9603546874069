var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.canAccess('UI_SERVER_LOGS:VIEW'))?_c('div',{staticClass:"mb-8"},[_c('v-btn',{staticClass:"pa-0",attrs:{"id":"iiotLogsLink1","href":`/opensearch/app/discover#/?_g` +
          `=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-5h,to:now))` +
          `&_a=(columns:!(message,log.source.address),filters:!(),` +
          `index:\'3b567de0-6e29-11ea-a758-05655b71ddc2\',sort:!(!(\'@timestamp\',desc)))`,"text":"","target":"_blank","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('auditLogs.information.managementSystemLogs'))+" ")]),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3",attrs:{"id":"iiotAuditLogsViewManagementSystemLogsDescription"}},[_vm._v(" "+_vm._s(_vm.$t('auditLogs.information.managementSystemLogsDescription'))+" ")])])],1):_vm._e(),(_vm.canAccess('UI_SERVER_AUDIT:VIEW'))?_c('div',[_c('v-btn',{staticClass:"pa-0",attrs:{"id":"iiotLogsLink2","href":`opensearch/app/dashboards#/view/09644e00-459b-11ee-8798-47ddfcea6256?_g=` +
          `(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-5h,to:now))` +
          `&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,` +
          `useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,` +
          `title:'MS%20Audit%20logs',viewMode:view)`,"text":"","target":"_blank","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('auditLogs.information.auditLogs'))+" ")]),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3",attrs:{"id":"iiotAuditLogsViewManagementSystemLogsDescription"}},[_vm._v(" "+_vm._s(_vm.$t('auditLogs.information.auditLogsDescription'))+" ")])])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }